<template>
    <div class="content" id="dashboard">
        <appSideNavigation></appSideNavigation>
        <appModal></appModal>
        <div class="main-content-container">
            <div class="site-header">
                <h3>{{name}}</h3>
            </div>
            <div class="user-rights-container" v-if="user && user.id">
                <div class="Daten">
                    <h5 v-if="user.fname !=='' && user.lname !== ''">Rechte für Benutzer: {{user.fname}} {{user.lname}} [{{user.mail}}]</h5>
                    <h5 v-else>Rechte für Benutzer: {{user.mail}}</h5>
                </div>
                <div class="row form" v-if="user">
                    <div class="Rechte" v-for="obj in statTemplates" :key="obj.id">
                        <div class="row">
                            <div class="twelve-columns">
                                <div>
                                    <p class="right-name">{{ obj.name }}</p>
                                </div>
                                <div class="rights">
                                    <label class="right-read">
                                        <input type="checkbox" :id="setInputID(obj.id, 'read')" :value="obj.id" data-type="read" :data-id="obj.id" :checked="checkRightStatus(user.id, obj.id, 'read')">
                                        <span class="label-body">Lesen</span>
                                    </label>
                                    <label class="right-write">
                                        <input type="checkbox" :id="setInputID(obj.id, 'write')" :value="obj.id" data-type="write" :data-id="obj.id" :checked="checkRightStatus(user.id, obj.id, 'write')">
                                        <span class="label-body">Schreiben</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="userButton">
                        <button class="button-primary" @click="saveUserRights">Speichern</button>
                    </div>
                </div>
            </div>
            <div v-else class="user-not-found-container">
                <p>Es wurde keine Benutzer gefunden.</p>
            </div>
        </div>
    </div>
</template>

<script>
import SideNavigation from '../components/SideNavigation.vue'
import Modal from '../components/Modal.vue'
import Util from '../mixins/util'

export default{
    data () {
        return {
            name: 'Benutzer',
            user: null,
            statTemplates: [],
            rights: [],
            sendingRightsList: []
        }
    },
    mounted: async function () {
        this.navigateDependingOnSession()
        this.user = await window.dataController.GetUserByID(this.$route.params.id)
        if (this.user) {
            this.statTemplates = await window.dataController.GetStatTemplates()
            this.rights = await window.dataController.GetUserStatRights(this.user.id)
        }
    },
    methods: {
        replaceIntoRightsList: function (e) {
            console.log('event', e)
        },
        setInputID: function (statID, rightType) {
            return 'input-' + statID + '-' + rightType
        },
        saveUserRights: async function () {
            var inputs = document.querySelectorAll('input')
            for (var i = 0; i < inputs.length; i++) {
                let index = this.sendingRightsList.findIndex(element => element.stat === inputs[i].dataset.id)
                if (index !== -1) {
                    if (inputs[i].dataset.type === 'read') {
                        this.sendingRightsList[index].read = inputs[i].checked ? 1 : 0
                    } else {
                        this.sendingRightsList[index].write = inputs[i].checked ? 1 : 0
                    }
                } else {
                    this.sendingRightsList.push({
                        user: this.user.id,
                        stat: inputs[i].dataset.id,
                        read: inputs[i].dataset.type === 'read' && inputs[i].checked ? 1 : 0,
                        write: inputs[i].dataset.type === 'write' && inputs[i].checked ? 1 : 0
                    })
                }
            }
            if (this.sendingRightsList.length > 0) {
                var isSuccessfull = await window.dataController.SetUserRights({userRights: this.sendingRightsList})
                if (isSuccessfull) {
                    window.dataController.ShowMessage('Die Benutzerrechte wurden erfolgreich gesetzt oder aktualisiert.', false, 'success')
                }
            }
        },
        checkRightStatus: function (userID, statID, rightType) {
            if (this.rights && this.rights.status && this.rights.status === 'ok') {
                if (this.rights.userRights && this.rights.userRights.length > 0) {
                    let userRight = this.rights.userRights.find(element => element.user === userID && element.stat === statID)
                    if (userRight) {
                        if (rightType === 'read') {
                            return userRight.read === "1" ? true : false
                        } else {
                            return userRight.write === "1" ? true : false
                        }
                    }
                }
            }
        }
    },
    components: {
        appSideNavigation: SideNavigation,
        appModal: Modal
    },
    mixins: [Util]
};
</script>

<style scoped>
.Rechte {
    background-color: white;
    border-radius: 20px;
    width: 45%;
    height: 100%;
    margin-bottom: 30px;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 2%;
    box-shadow:4px 7px 10px rgba(0,0,0,.4);
}
.Daten {
    padding: 20px;
    text-align: center;
}
p.right-name {
    font-weight: bold;
}
.userButton{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 94%;
}
/*
form button[data-v-cce78492] {
    margin-top: 2%;
    margin-left: 42%;
    display: block;
    width: 300px;
    box-shadow: 4px 7px 10px rgb(0 0 0 / 40%); 
}*/
.user-not-found-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.user-not-found-container p {
    font-size: 22px;
    margin: 0;
    font-weight: bold;
}
@media (max-width: 550px) {
    .Rechte{
        width: 96%;
    }
    form button[data-v-cce78492] {
        margin-right: 11%;
        display: block;
        width: 250px;
        box-shadow: 4px 7px 10px rgb(0 0 0 / 40%);
    }
}

</style>
